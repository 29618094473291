<template>
  <div class="base-message">
    <!--    <div v-for="(item, i) in parseHref(message)" :key="i">-->
    <div v-for="(item, i) in modifyText" :key="i">
      <!--        v-if="item.length > 1 || i === 0"-->
      <vue-markdown
        :highlight="true"
        :typographer="true"
        quotes="«»„“"
        class="base-message__item"
        >{{ item }}</vue-markdown
      >
      <!--      <br v-else />-->
    </div>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown';
export default {
  name: 'BaseMessage',
  components: { VueMarkdown },
  props: {
    message: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modifyText: '',
    };
  },
  computed: {
    // parseText() {
    //   return this.message.split('\n');
    // },
  },
  async mounted() {
    this.parseHref(this.message);
  },
  methods: {
    parseHref(message) {
      this.modifyText = message
        .replaceAll('<ol>\n<li>', '<ol><li>')
        .replaceAll('</li>\n<li>', '</li><li>')
        .split('\n');
      return message.replaceAll('<ol>\n<li>', '<ol><li>').split('\n');
    },
    checkEmpty(t) {
      console.log(t.length);
      return t.length;
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.base-message {
  overflow : hidden;
  &__item {
    //white-space: pre-wrap;
    overflow-wrap: anywhere;
    justify-content: flex-start;
    //align-items: stretch;
    width: 100%;


    &-m{
    //min-heigth: 10px;

    }
    &-null{
      heigth: 10px;

    }
    &-flex {
      width: 100%;

      justify-content: flex-start;
      overflow-wrap: anywhere;
      align-items: stretch;

    display: flex;
    flex-wrap: wrap;
    }
  }

}
</style>
